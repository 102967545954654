import React from 'react';

import TopNav from './Components/TopNav';
import HeroTile from './Components/HeroTile';
import Tile from './Components/Tile';
import WorkTile from './Components/WorkTile';
import MetalTile from './Components/MetalTile';
import { Carousel } from 'react-responsive-carousel';

import MetalConstruction from './assets/images/metalconstruction.png';
import OutdoorStructures from './assets/images/outdoorstructures.png';
import CustomHomes from './assets/images/customhomes.png';
import Ribbon from './assets/images/ribbon.svg';
import Paintbrush from './assets/images/paintbrush.svg';
import Diamond from './assets/images/diamond.svg';
import WorkWithUsHero from './assets/images/workwithus-hero.png';

import './App.css';
import './assets/Slider.css';

//gallery images
import Img1 from './assets/images/gallery/IMG_3631.jpg';
import Img2 from './assets/images/gallery/IMG_3632.jpg';
import Img3 from './assets/images/gallery/IMG_3633.jpg';
import Img4 from './assets/images/gallery/IMG_3635.jpg';
import Img5 from './assets/images/gallery/IMG_3636.jpg';
import Img6 from './assets/images/gallery/IMG_3637.jpg';
import Img7 from './assets/images/gallery/IMG_3638.jpg';
import Img9 from './assets/images/gallery/IMG_3640.jpg';
import Img10 from './assets/images/gallery/IMG_3644.jpg';
import Img11 from './assets/images/gallery/IMG_3648.jpg';
import Img12 from './assets/images/gallery/IMG_3649.jpg';

function App() {
  const [galleryVis, setGalleryVis] = React.useState(false);
  const toggleGallery = () => setGalleryVis(!galleryVis);

  const handleContactUs = _ => {
    console.log('handleContactUs from App.js reached');
  };

  const imgStyle = {
    maxHeight: 'calc(100vh - 125px)',
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
  };

  return (
    <>
      {console.log(galleryVis)}
      <TopNav galleryVis={galleryVis} toggleGallery={toggleGallery} contact-us={handleContactUs} />
      {galleryVis && (
        <div className='gallery'>
          <Carousel autoPlay={true} dynamicHeight={true}>
            <div>
              <img src={Img1} style={imgStyle} alt='one' />
            </div>
            <div>
              <img src={Img2} style={imgStyle} alt='one' />
            </div>
            <div>
              <img src={Img3} style={imgStyle} alt='one' />
            </div>
            <div>
              <img src={Img4} style={imgStyle} alt='one' />
            </div>
            <div>
              <img src={Img5} style={imgStyle} alt='one' />
            </div>
            <div>
              <img src={Img6} style={imgStyle} alt='one' />
            </div>
            <div>
              <img src={Img7} style={imgStyle} alt='one' />
            </div>
            <div>
              <img src={Img9} style={imgStyle} alt='one' />
            </div>
            <div>
              <img src={Img10} style={imgStyle} alt='one' />
            </div>
            <div>
              <img src={Img11} style={imgStyle} alt='one' />
            </div>
            <div>
              <img src={Img12} style={imgStyle} alt='one' />
            </div>
          </Carousel>
        </div>
      )}
      {!galleryVis && (
        <div className='content'>
          <HeroTile contact-us={handleContactUs} />
          <div className='three-tiles'>
            <Tile
              image={MetalConstruction}
              header='Metal Construction'
              copy='Durable, affordable metal construction with customized floor plans.'
            />
            <Tile
              image={OutdoorStructures}
              header='Outdoor Structures'
              copy='We build shops and barns, outdoor living areas, kitchens, custom pools and more.'
            />
            <Tile
              image={CustomHomes}
              header='Custom Homes'
              copy='Build your home with traditional 2x4 construction. Customize an existing floor plan or bring your own.'
            />
          </div>
          <div className='work-with-us-container'>
            <div className='work-with-us-image'>
              <img src={WorkWithUsHero} alt='Work with Cari and Mike' />
            </div>
            <div className='work-with-us'>
              <div className='work-with-us-title'>
                <u>Work</u> with Cari & Mike
              </div>
              <WorkTile
                image={Ribbon}
                header='Over 40 Years of Experience'
                copy='Established in 1999 with over 40 years of combined experience. Having never advertised, repeat and referals are our mainstay.'
              />
              <WorkTile
                image={Paintbrush}
                header='Personalized Experience'
                copy='Your design can be drawn from scratch or we have floorplans to choose from. Any design may be altered for your specific needs and desires.'
              />
              <WorkTile
                image={Diamond}
                header='Value for Your Money'
                copy='Elite to simple exterior and interior designs. Working together to enhance your buget, we offer a free interior design consultation. '
              />
            </div>
          </div>

          {/* Testimonials */}

          {/* TODO Testimonial mobile formatting */}
          <div class='testimonial-slider'>
            <div className='slider-title'>What our customers are saying</div>
            <ul class='slider'>
              <li>
                <div class='testimonial-slider-content'>
                  <q>
                    I have used Cari Hester to remodel three homes now, and she has done a fantastic
                    job on each one: from removing tile floors and replacing with wood, tearing out
                    kitchen cabinets and rebuilding a pantry, to replacing granite counter tops in
                    two of my homes, to remodeling bathrooms in all three of my homes! I'm very
                    happy with the quality of work that she does and with the help she gives me in
                    picking out materials". It's always a pleasure to trust Cari with my homes - I
                    feel confident in leaving everything in her care. I highly recommend Cari's
                    Remodeling!
                  </q>
                  <p class='source'>- Ellen T.</p>
                </div>
              </li>
              <li>
                <div class='testimonial-slider-content'>
                  <q>
                    We had a wonderful experience working with Cari and Mike, who maintain high
                    standards in their skills and professional ethics. We were told, in as much
                    detail as we wanted, exactly what they would do, the materials used, and the
                    subcontractors hired. We were confident with everyone throughout the planning
                    and building processes and they were open to modifications when we approached
                    them with a change in our plans. Their work ethic is remarkable and the job was
                    well planned and executed.
                  </q>
                  <p class='source'>- Gina</p>
                </div>
              </li>
              <li>
                <div class='testimonial-slider-content'>
                  <q>
                    Mike and Cari built our barndominium in 2020. We are so happy with our
                    home/shop. From concept to moving in, Mike and Cari were on-time and on-budget.
                    They had great ideas and suggestions to make our house better. The quality and
                    craftsmanship that went into our house are the same that Mike and Cari built
                    into their own home. We heartily recommend Mike and Cari to anyone who is
                    looking to build the home of their dreams.
                  </q>
                  <p class='source'>- Jim & Susan</p>
                </div>
              </li>
            </ul>
          </div>

          <MetalTile />
        </div>
      )}
    </>
  );
}

export default App;
