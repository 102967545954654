import React from 'react';
import '../assets/HeroTile.css';
import HeroBg from '../assets/images/herobg-clear.png';
import Dialogue from './Dialogue';

const HeroTile = props => {
  let title = 'Build Your Dream Home';
  let description =
    'You can trust Cari & Mike to make your dreams a reality. We have customizable floor plans or you can bring your own. Feel free to pick our brain to make the process less overwhelming.';
  let buttonText = 'Message Cari & Mike';
  let buttonAction = props.handleContactUs;

  return (
    <div className='hero-container'>
      <img className='hero-img' src={HeroBg} alt='bg house' />
      <div className='welcome'>
        <Dialogue
          title={title}
          description={description}
          buttonText={buttonText}
          buttonLink={buttonAction}
        />
      </div>
    </div>
  );
};

export default HeroTile;
