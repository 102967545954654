import React from 'react';
import '../assets/MetalTile.css';
import FeatureTile from '../Components/FeatureTile';
import Leaf from '../assets/images/leaf.svg';
import Metal from '../assets/images/metal.svg';
import Weight from '../assets/images/weight.svg';
import RedBarn from '../assets/images/redbarn.png';
import LongHouse from '../assets/images/longhouse.png';
import GreyShed from '../assets/images/greyshed.png';
import Roof1 from '../assets/images/roof1.png';
import SteelFrame from '../assets/images/steelframe.png';
import Roof2 from '../assets/images/roof2.png';

const MetalTile = props => {
  var qualityCopy = (
    <span>
      We use <strong>Mueller</strong> steel construction for metal roofing and siding that stands
      the test of time
    </span>
  );
  var durabilityCopy = (
    <span>
      Metal buildlings last <strong>50-100 years</strong> with minimal maintenance or repairs
    </span>
  );

  var efficiencyCopy = (
    <span>
      As much as 30% reduction in heat gain through the roof to help{' '}
      <strong>lower your energy bills</strong>
    </span>
  );

  var highlightCopy = (
    <span>
      A typical metal home can be constructed in as little as <strong>3-6 months</strong> depending
      on material delivery!
    </span>
  );

  return (
    <div className='metaltile-container'>
      <div className='metaltile-header'>We Build Custom Metal Homes & Buildings</div>
      <div className='feature-tile-container'>
        <FeatureTile title='Quality' svg={Metal} copy={qualityCopy} />
        <FeatureTile title='Durability' svg={Weight} copy={durabilityCopy} />
        <FeatureTile title='Efficiency' svg={Leaf} copy={efficiencyCopy} />
      </div>
      <div className='img-row'>
        <img src={RedBarn} alt='red barn' />
        <img src={LongHouse} alt='long metal house' />
        <img src={GreyShed} alt='grey shed' />
      </div>
      <div className='text-highlight'>{highlightCopy}</div>
      <div className='img-row' id='bottom'>
        <img src={Roof1} alt='metal roof' />
        <img src={SteelFrame} alt='steel frame construction' />
        <img src={Roof2} alt='second metal roof' />
      </div>
    </div>
  );
};

export default MetalTile;
