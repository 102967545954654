import React from 'react';
import '../assets/Tile.css';

const Tile = props => {
  return (
    <div className='tile-container'>
      <img className='tile-img' src={props.image} alt='tile' />
      <div className='tile-content'>
        <div className='tile-header'>{props.header}</div>
        <div className='tile-copy'>{props.copy}</div>
      </div>
    </div>
  );
};

export default Tile;
