// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
  display: flex;
  width: 100%;
  padding: 10px 45px;
  background: rgba(0, 0, 0, 0.75);
}

.left-border {
  position: absolute;
  height: 100%;
  left: 0px;
  top: 0px;
  border: 2px solid #6b3cb9;
}

.right-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #FFFFFF;
  gap: 25px;
}

.title{
  font-size: 32px;
}

.description{
  font-size: 20px;
}

.dialogue-button{
  width: 200px;
}

@media (max-width: 415px){
  .dialogue-button{
    display:none;
  }

  .main-container{
    padding: 10px 25px 20px 25px;
    background: rgba(0, 0, 0, 0.5);
  }

  .title{
    font-size: 20px;
  }

  .description{
    font-size: 16px;
    text-align: justify;
  }

  .right-content{
    gap: 8px;
  }

  .left-border{
    border: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/Dialogue.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,WAAW;EACX,kBAAkB;EAClB,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,QAAQ;EACR,yBAAyB;AAC3B;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,4BAA4B;IAC5B,8BAA8B;EAChC;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,QAAQ;EACV;;EAEA;IACE,YAAY;EACd;AACF","sourcesContent":[".main-container {\n  display: flex;\n  width: 100%;\n  padding: 10px 45px;\n  background: rgba(0, 0, 0, 0.75);\n}\n\n.left-border {\n  position: absolute;\n  height: 100%;\n  left: 0px;\n  top: 0px;\n  border: 2px solid #6b3cb9;\n}\n\n.right-content{\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  color: #FFFFFF;\n  gap: 25px;\n}\n\n.title{\n  font-size: 32px;\n}\n\n.description{\n  font-size: 20px;\n}\n\n.dialogue-button{\n  width: 200px;\n}\n\n@media (max-width: 415px){\n  .dialogue-button{\n    display:none;\n  }\n\n  .main-container{\n    padding: 10px 25px 20px 25px;\n    background: rgba(0, 0, 0, 0.5);\n  }\n\n  .title{\n    font-size: 20px;\n  }\n\n  .description{\n    font-size: 16px;\n    text-align: justify;\n  }\n\n  .right-content{\n    gap: 8px;\n  }\n\n  .left-border{\n    border: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
