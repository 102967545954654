import React from 'react';
import '../assets/WorkTile.css';

const WorkTile = props => {
  return (
    <div className='work-container'>
      <div className='work-left'>
        <img className='work-img' src={props.image} alt='tile' />
      </div>
      <div className='work-content'>
        <div className='work-header'>{props.header}</div>
        <div className='work-copy'>{props.copy}</div>
      </div>
    </div>
  );
};

export default WorkTile;
