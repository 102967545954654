// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tile-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 349px;
  height: 441px;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
}

.tile-img{
  background-color: none;
  width: 100%;
}

.tile-content {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  padding: 25px;
  gap: 30px;

  width: 100%;
  height: 100%;

  border: 1px solid #d3d3d3;
}

.tile-header {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 27px;
  /* identical to box height */

  color: #000000;
}

.tile-copy {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #999999;
}

@media (max-width: 415px){
  .tile-container{
    width: 65%;
    height: auto;
  }

  .tile-content{
    gap:1rem;
  }

  .tile-header{
    font-size: 20px;
  }

  .tile-copy{
    font-size: 16px;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/Tile.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;;EAEnB,YAAY;EACZ,aAAa;;EAEb,2CAA2C;AAC7C;;AAEA;EACE,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,sBAAsB;;EAEtB,aAAa;EACb,sBAAsB;EACtB,oBAAoB;EACpB,mBAAmB;EACnB,aAAa;EACb,SAAS;;EAET,WAAW;EACX,YAAY;;EAEZ,yBAAyB;AAC3B;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,4BAA4B;;EAE5B,cAAc;AAChB;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,kBAAkB;;EAElB,cAAc;AAChB;;AAEA;EACE;IACE,UAAU;IACV,YAAY;EACd;;EAEA;IACE,QAAQ;EACV;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".tile-container {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n\n  width: 349px;\n  height: 441px;\n\n  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);\n}\n\n.tile-img{\n  background-color: none;\n  width: 100%;\n}\n\n.tile-content {\n  box-sizing: border-box;\n\n  display: flex;\n  flex-direction: column;\n  justify-content: top;\n  align-items: center;\n  padding: 25px;\n  gap: 30px;\n\n  width: 100%;\n  height: 100%;\n\n  border: 1px solid #d3d3d3;\n}\n\n.tile-header {\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 22px;\n  line-height: 27px;\n  /* identical to box height */\n\n  color: #000000;\n}\n\n.tile-copy {\n  font-family: 'Inter';\n  font-style: normal;\n  font-weight: 400;\n  font-size: 18px;\n  line-height: 22px;\n  display: flex;\n  align-items: center;\n  text-align: center;\n\n  color: #999999;\n}\n\n@media (max-width: 415px){\n  .tile-container{\n    width: 65%;\n    height: auto;\n  }\n\n  .tile-content{\n    gap:1rem;\n  }\n\n  .tile-header{\n    font-size: 20px;\n  }\n\n  .tile-copy{\n    font-size: 16px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
