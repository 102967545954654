import React from 'react';
import '../assets/Dialogue.css';

const Dialogue = props => {
  return (
    <div className="main-container">
        <div className="left-border" />
        <div className="right-content">
            <div className='title'>{props.title}</div>
            <div className='description'>{props.description}</div>
            <button className='button dialogue-button' onClick={props.buttonAction}>{props.buttonText}</button>
        </div>
    </div>
  );
};

export default Dialogue;