// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/herobg-mobile.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hero-container{
  position: relative;
}

.hero-img{
  width: 100%;
}
.welcome{
  position: absolute;
  left: 85px;
  top: 30%;
  width: 40%;
}

@media (max-width: 700px) {
  .hero-img {
    content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-color: #6b3cb9;
  }
}

@media (max-width: 415px){
  .welcome{
    width: 100%;
    bottom: 0px;
    left: 0px;
    top: revert;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/HeroTile.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE;IACE,gDAAwC;IACxC,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,WAAW;IACX,WAAW;IACX,SAAS;IACT,WAAW;EACb;AACF","sourcesContent":[".hero-container{\n  position: relative;\n}\n\n.hero-img{\n  width: 100%;\n}\n.welcome{\n  position: absolute;\n  left: 85px;\n  top: 30%;\n  width: 40%;\n}\n\n@media (max-width: 700px) {\n  .hero-img {\n    content: url(./images/herobg-mobile.png);\n    background-color: #6b3cb9;\n  }\n}\n\n@media (max-width: 415px){\n  .welcome{\n    width: 100%;\n    bottom: 0px;\n    left: 0px;\n    top: revert;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
