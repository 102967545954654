import React from 'react';
import '../assets/TopNav.css';

const TopNav = props => {
  return (
    <div className='nav'>
      <div className='nav-header'>
        <div className='nav-title'>Creative Homes</div>
        <div className='nav-title sub'>by Cari & Mike</div>
      </div>
      <span className='side-button-container'>
        <span className='button nav-button' onClick={props.toggleGallery}>{props.galleryVis ? 'Close Gallery' : 'Gallery'}</span>
        {/* TODO contact us form and email integration */}
        <span className='button nav-button'>Contact Us</span>
      </span>
    </div>
  );
};

export default TopNav;