import React from 'react';
import '../assets/FeatureTile.css';

const FeatureTile = props => {
  return (
    <div className='feature-container'>
      <div className='feature-title'>{props.title}</div>
      <img src={props.svg} alt='icon' className='feature-svg' />
      <div className='feature-copy'>{props.copy}</div>
    </div>
  );
};

export default FeatureTile;
